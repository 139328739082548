<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="1000px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <div class="df-row">
          <div class="df-col flex-2">
            <span class="label">名称</span>
            <el-input
              style="width: 280px;"
              maxlength="20"
              v-model="submitData.Name"
              placeholder="请输入协议名称，最多20个字"
              size="mini"
            ></el-input>
          </div>

          <div class="df-col flex-2">
            <span class="label">最少阅读时间</span>
            <el-input
              style="width: 80px;"
              v-model="submitData.ReadLeast"
              placeholder="请输入秒数"
              size="mini"
            ></el-input>
          </div>

          <div class="df-col flex-1">
            <span class="label">显示状态</span>
            <el-switch
              v-model="submitData.ShowMark"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </div>

          <div class="df-col flex-1">
            <span class="label">必须签名</span>
            <el-switch
              v-model="submitData.MustSign"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </div>
        </div>

        <div class="sign-box">
          <div class="sign-word">
            <div
              class="work-item"
              v-for="(item, index) in signWordList"
              :key="index"
              @click="onSelectSignWord(item)"
            >
              <i :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </div>
          </div>

          <!-- 文本编辑器 -->
          <quillEditor
            id="editor"
            ref="myQuillEditor"
            :options="options"
            v-model="submitData.Detail"
            @ready="onEditorReady($event)"
            @blur="onEditorBlur"
            @focus="onEditorFocus"
          ></quillEditor>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveContract">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Contract from "@/api/contract.js";

import Quill from "quill";
import { quillEditor } from "vue-quill-editor";
import { lineHeightStyle } from "@/utils/lineHeight";

let toolbarOptions = [
  ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
  ["blockquote", "code-block"], //引用，代码块
  [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
  [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
  [{ list: "ordered" }, { list: "bullet" }], //列表
  [{ script: "sub" }, { script: "super" }], // 上下标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  [{ lineheight: ["initial", "1", "1.5", "1.75", "2", "3", "4", "5"] }],
  [{ direction: "rtl" }], // 文本方向
  [{ size: ["small", false, "large", "huge"] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
  [{ font: [] }], //字体
  [{ align: [] }], //对齐方式
  // ["image", "video"],
  // ["gallerypictrue"],
  // ["video"],
  // ["html"],
  ["clean"], //清除字体样式
];
export default {
  components: { quillEditor },

  data() {
    return {
      title: "", // 标题
      editIndex: 0, // 编辑器鼠标光标下标
      options: {
        // VueQuillEditor配置
        placeholder: "请在这里输入",
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              lineheight: (value) => {
                if (value) {
                  let quill = this.$refs.myQuillEditor.quill;
                  quill.format("lineHeight", value);
                }
              },
              gallerypictrue: (value) => {
                if (value) {
                  let quill = this.$refs.myQuillEditor.quill;
                  // this.editIndex = quill.getSelection().index;
                  this.$refs.DFGalleryPictrue.onShowDialog();
                }
              },
              // html: (value) => {
              //   if (true) {
              //     let quill = this.$refs.myQuillEditor.quill;
              //     this.$refs.myQuillEditor.enable(false);
              //   }
              // },
            },
          },
        },
      },
      submitData: {
        ContractGuid: "", // 协议id
        Name: "", // 协议名称
        ReadLeast: 0, // 阅读时间
        ShowMark: 1, // 显示状态
        MustSign: 1, // 必须签名
        Detail: "", // 协议内容
      },
      signWordList: [
        {
          icon: "el-icon-phone-outline",
          name: "门店电话",
          sign: "#门店电话",
        },
        {
          icon: "dfWebIcon dfWebIcon-mendian",
          name: "门店名称",
          sign: "#门店名称",
        },
        {
          icon: "dfWebIcon dfWebIcon-dingwei",
          name: "门店地址",
          sign: "#门店地址",
        },
        {
          icon: "dfWebIcon dfWebIcon-dingdan",
          name: "订单编号",
          sign: "#订单编号",
        },
        {
          icon: "dfWebIcon dfWebIcon-huiyuanka",
          name: "会员卡名",
          sign: "#会员卡名",
        },
        {
          icon: "dfWebIcon dfWebIcon-a-icon-zuixin-xiaofeimingxi-o",
          name: "消费明细",
          sign: "#消费明细",
        },
        {
          icon: "dfWebIcon dfWebIcon-jine",
          name: "订单金额",
          sign: "#订单金额",
        },
        {
          icon: "dfWebIcon dfWebIcon-xiaoshouren",
          name: "销售人",
          sign: "#销售人",
        },
        {
          icon: "dfWebIcon dfWebIcon-zhifufangshi",
          name: "支付方式",
          sign: "#支付方式",
        },
        {
          icon: "dfWebIcon dfWebIcon-riqi",
          name: "购买日期",
          sign: "#购买日期",
        },
        {
          icon: "dfWebIcon dfWebIcon-riqi1",
          name: "有效日期",
          sign: "#有效日期",
        },
        {
          icon: "dfWebIcon dfWebIcon-qianming",
          name: "客户签名",
          sign: "#客户签名",
        },
        {
          icon: "dfWebIcon dfWebIcon-im_kehu",
          name: "客户姓名",
          sign: "#客户姓名",
        },
        {
          icon: "dfWebIcon dfWebIcon-ic_shouji",
          name: "客户手机",
          sign: "#客户手机",
        },
        {
          icon: "dfWebIcon dfWebIcon-chongzhizengsong",
          name: "充值权益",
          sign: "#充值权益",
        },
        {
          icon: "dfWebIcon dfWebIcon-zhekou1",
          name: "折扣权益",
          sign: "#折扣权益",
        },
        {
          icon: "dfWebIcon dfWebIcon-wodehuiyuanqia",
          name: "疗程卡项权益",
          sign: "#疗程卡项权益",
        },
        {
          icon: "dfWebIcon dfWebIcon-wodehuiyuanqia1",
          name: "赠送卡项权益",
          sign: "#赠送卡项权益",
        },
      ],
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 自定义blot
    stockBlot() {
      // 引入源码中的BlockEmbed
      const BlockEmbed = Quill.import("blots/block/embed");
      // 定义新的blot类型
      class StockEmbed extends BlockEmbed {
        static create(value) {
          const node = super.create(value);
          // node.setAttribute('contenteditable', 'false');
          // node.setAttribute('width', '100%');
          //   设置自定义html
          node.innerHTML = value;
          return node;
        }
        // 去除字符串模板(``)语法中存在的空格
        static transformValue(value) {
          let handleArr = value.split("\n");
          handleArr = handleArr.map((e) =>
            e.replace(/^[\s]+/, "").replace(/[\s]+$/, "")
          );
          return handleArr.join("");
        }

        // 返回节点自身的value值 用于撤销操作
        static value(node) {
          return node.innerHTML;
        }
      }
      StockEmbed.blotName = "StockEmbed";
      // StockEmbed.className = "sign-flag";
      StockEmbed.tagName = "p";
      Quill.register(StockEmbed, true);
    },

    // 初始化修改协议
    async initContract() {
      try {
        let { data } = await Contract.initContract({
          contract_guid: this.submitData.ContractGuid,
        });
        if (data) {
          this.submitData = data;
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 保存协议
    async saveContract() {
      let { submitData } = this;
      if (!submitData.Name) {
        return this.$message.error("请输入协议名称");
      } else if (submitData.Name.length > 20) {
        return this.$message.error("协议名称不能超过20个字");
      } else if (!submitData.Detail) {
        return this.$message.error("请输入协议内容");
      }

      try {
        let { errcode } = await Contract.saveContract(this.submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 准备富文本编辑器
    onEditorReady(event) {
      // 注册行高拓展
      Quill.register({ "formats/lineHeight": lineHeightStyle }, true);

      // 自定义编辑器拓展，目前只找到一个比较简单的方法直接给dom塞标签或图标样式
      let demo = document.querySelector(".ql-html");
      // demo.innerHTML = "<span>源码编辑器</span>";
      demo.className = "dfWebIcon dfWebIcon-outline-html-5";
      setTimeout(() => {
        this.stockBlot();
        event.blur();
      }, 100);
    },

    // 编辑器焦点结束事件
    onEditorBlur() {
      // console.log('mmmm');
      // this.$emit('onBlurText', this.note)
    },

    // 编辑器焦点开始事件
    onEditorFocus() {
      // let quill = this.$refs.myQuillEditor.quill;
      // let index = quill.selection.savedRange.index;
      // quill.setSelection(index);
      // console.log(index);
    },

    // 选中模板关键词
    onSelectSignWord(event) {
      let quill = this.$refs.myQuillEditor.quill,
        index = quill.selection.savedRange.index;

      quill.insertText(index, event.sign);
      // quill.insertEmbed(
      //   index,
      //   event.sign
      //   // "StockEmbed",
      //   // `<span class="sign-flag">${event.sign}</span>`
      // );
      // setTimeout(() => {
      let note = this.submitData.Detail.replace(
        /\<p\>\<br\>\<\/p\>||\<p\>\<\/p\>/g,
        ""
      );
      this.submitData.Detail = note;
      // index = note.length;
      index += event.sign.length;
      // setTimeout(() => {
      console.log(this.submitData.Detail);
      // quill.setSelection(index);
      //   }, 100);
      // }, 200);
    },

    // 重置页面数据
    resetPageData() {
      this.submitData = {
        ContractGuid: "",
        Name: "",
        ReadLeast: 0,
        ShowMark: 1,
        MustSign: 1,
        Detail: "",
      };
    },

    // 打开对话框
    onShowDialog(param = "") {
      this.title = `${param ? "修改" : "新增"}协议`;
      this.submitData.ContractGuid = param;
      this.initContract();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetPageData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .df-row {
    .df-col {
      .flex-row;

      .label {
        display: block;
        width: auto;
        white-space: nowrap;
        margin-right: 10px;
      }
    }

    // .flex-1 {
    //   flex: 1;
    // }

    // .flex-2 {
    //   flex: 1;
    // }
  }

  .sign-box {
    height: 50vh;
    .sign-word {
      .flex-row;
      flex-wrap: wrap;
      .work-item {
        .flex-col;
        margin: 10px 20px 10px 0;
        cursor: pointer;

        i {
          font-size: 20px;
          padding-bottom: 5px;
        }

        span {
          font-size: 10px;
        }
      }
    }
  }
}

.quill-editor {
  width: 100%;
  height: 30vh;
  ::v-deep .ql-container {
    font-size: 14px;
    // height: calc(100vh - 146px);
    // .ql-editor {
    //   height: calc(100vh - 146px);
    // }
  }
}
</style>
