
import { request } from "../utils/axios.js";
export default {
	// 会员 ====> 会员办卡协议
	getContractMaintList(params = {}){
		return request('get','/contract/get_contract_maint_list', params);
	},

    initContract(params = {}){
		return request('get','/contract/init_contract', params);
	},

    saveContract(params = {}){
		return request('post','/contract/save_contract', params);
	},

    showContract(params = {}){
		return request('get','/contract/show_contract', params);
	},

    deleteContract(params = {}){
		return request('get','/contract/delete_contract', params);
	},
}